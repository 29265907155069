<template>
<div id="hrManager">
    <section class="hero-section pt-100 background-img" >
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6 col-lg-6">
                    <div class="hero-content-left text-white ">
                        <!--<span class="text-uppercase h6">Customer First Priority</span>-->
                        <h1 class="text-white font-weight-bold">I vantaggi per gli HR Manager</h1>
                        <p class="lead">Offri ai tuoi dipendenti i migliori dispositivi sul mercato e massimizza la produttività aziendale con l’offerta DaaS di Noltech.</p>

                        <button class="configuration-btn" @click="selectDevice(selectedDevice)">Configura i tuoi device</button>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <div class="hero-animation-img">
                        <img src="img/viaggiatore3.png" alt=" L'offerta DaaS pensata per gli HR Manager" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="promo-section mt-5 ">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                        <div class="flip-div">
                            <div class="flip-main">
                                <div class="front">
                                    <div class=" single-promo-1">
                                        <div class="card-body text-center pb-2" style="min-height: 250px;">
                                            <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                            <h4 class="card-title numcard">6,58 milioni</h4>
                                            <p class="card-text">
                                                Lavoratori attivi da remoto in Italia*
                                            </p>
                                            <p>
                                                (School of Management del Politecnico di Milano 2020)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="back">
                                    <div class="single-promo-1" style="min-height: 250px">
                                        <div class="card-body text-center">
                                            <p class="card-text">* In caso di smart working, consegniamo i dispositivi già configurati direttamente a casa dei tuoi collaboratori con la tua lettera di benvenuto.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 250px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">42%</h4>
                                        <p class="card-text">
                                            Lavoratori che hanno dovuto usare pc personali in smart working*
                                        </p>
                                        <p>
                                            (INPS 2021)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 250px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* Con Noltech puoi modificare il tuo parco IT in tempi record, garantendo l’operatività dei tuoi dipendenti e gestendo anche onboarding massivi.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 250px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">+82%</h4>
                                        <p class="card-text">
                                            Retention dei dipendenti con onboarding experience eccellente*
                                        </p>
                                        <p>
                                            (Glassdoor 2015)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 250px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* Migliora l’esperienza di onboarding offrendo ai tuoi collaboratori dispositivi di qualità e ready-to-use.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="flip-div">
                        <div class="flip-main">
                            <div class="front">
                                <div class=" single-promo-1">
                                    <div class="card-body text-center pb-2" style="min-height: 200px;">
                                        <p><!--<img class="rounded-circle" src="http://nicesnippets.com/demo/profile-1.jpg">--></p>
                                        <h4 class="card-title numcard">77%</h4>
                                        <p class="card-text">
                                            Lavoratori che ritengono le dotazioni come parte della employee experience*
                                        </p>
                                        <p>
                                            (Qualtrics 2019)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="back ">
                                <div class="single-promo-1" style="min-height: 250px">
                                    <div class="card-body text-center">
                                        <p class="card-text">* I device top di gamma rientrano sempre più spesso nel concetto di benefit. CYOD e tematiche annesse non saranno più un problema.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-8">
                    <div class="section-heading text-center  mb-5">
                        <h2>Rafforza il tuo brand</h2>
                        <p class="lead">
                            I dispositivi tecnologici non sono solamente uno strumento di lavoro, ma rispecchiano anche l’attenzione della tua azienda verso i dipendenti impattando la tua strategia di employer branding. Con l’offerta DaaS di Noltech i tuoi collaboratori riceveranno device di ultima generazione e un servizio personalizzato che li renderà immediatamente operativi arricchendo la loro esperienza di onboarding con un device che sempre più spesso è associato ad un concetto di benefit. Sono dettagli che rafforzano lo spirito di appartenenza e migliorano la produttività.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="promo-section ptb-60">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>I tratti distintivi del Daas di Noltech</h2>
                        <p class="lead">
                            Siamo appassionati di tecnologia da più di 50 anni e sappiamo quanto ricevere un nuovo dispositivo con servizi personalizzati sia gratificante, ecco perché siamo i tuoi compagni di viaggio ideali!
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                            <img src="/img/semplice.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>SEMPLICE</h5>
                        <p>Disponibilità di device e consegna in tempi record</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                           <img src="/img/flessibile.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>FLESSIBILE</h5>
                        <p>Ci adattiamo alle necessità della tua azienda</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                        <div class="circle-icon mb-5">
                            <img src="/img/sottoControllo.svg" width="30" alt="Easy icon" class="img-fluid">
                        </div>
                        <h5>SOTTO CONTROLLO</h5>
                        <p>Monitora lo status dei singoli dispositivi con un click</p>
                    </div>
                </div>
            </div>
        </div>
    </section>




    <section id="noltechStart" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="about-content-left section-heading">
                        <h2>Il pacchetto Noltech Start</h2>
                        <p>Mettiamo a tua disposizione i migliori dispositivi e un’assistenza di prima qualità per supportare l’operatività della tua azienda.</p>
                        <div class="single-feature mb-4 mt-5">
                            <div class="icon-box-wrap d-flex align-items-center mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/ultimaGenerazione.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Device di ultima generazione </strong> –  Gli strumenti in dotazione sono il biglietto da visita della tua azienda verso i suoi dipendenti. Fornisci ai tuoi collaboratori i <strong class="font-weight-bold"> migliori device </strong>scegliendo tra gli ultimi modelli dei principali vendor del mercato.</p>
                            </div>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/staging.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Staging personalizzato</strong> –  Rompere le scatole non è mai stato così divertente! Insieme ai device già configurati dai nostri tecnici, i tuoi collaboratori riceveranno un <strong class="font-weight-bold"> communication kit personalizzato</strong> per iniziare il viaggio con il giusto entusiasmo.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/cyberSicuro.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Cybersecurity integrata</strong> – Nel 2020 si sono verificati più di 85.000 attacchi a pc personali (Clusit 2021), per questo proteggere i tuoi utenti è fondamentale. Noltech è l’unico provider che ti offre un <strong class="font-weight-bold"> servizio di cyber security avanzato</strong> incluso nel canone.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/myelmecBlu.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> MyElmec </strong>–  Trovare i dispositivi e analizzare le performance non è mai stato così semplice. La piattaforma MyElmec è stata pensata per consentirti di avere una <strong class="font-weight-bold"> visione completa </strong>dei tuoi servizi e ridurre il down time delle postazioni aziendali.</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="about-content-right">
                        <img src="img/zainoHR.svg" alt=" L'offerta Noltech Start per l'HR Manager" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>





    <section id="personalizzaOfferta" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-5">
                    <div class="about-content-right">
                        <img src="img/mappa.svg" alt=" I servizi aggiuntivi dell'offerta Device as a Service" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="about-content-left section-heading">
                        <h2>Personalizza il tuo pacchetto DaaS</h2>
                        <p>Arricchisci l’offerta Noltech con dei servizi complementari pensati per migliorare l’operatività della tua azienda.</p>
                        <div class="single-feature mb-4 mt-5">
                            <div class="icon-box-wrap d-flex align-items-center mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/smartRollout.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Smart Rollout </strong>– Le postazioni di lavoro vanno rinnovate sempre più di frequente, ma solitamente questa operazione rallenta l’operatività. Con il servizio di Smart Rollout i nostri tecnici gestiscono la <strong class="font-weight-bold"> sostituzione dei vecchi dispositivi</strong> direttamente nelle tue sedi, senza bisogno di interrompere le attività di business.  </p>
                            </div>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/wiping.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold">Blocco remoto e wiping</strong> – Il mondo del lavoro è sempre più dinamico e può capitare la necessità di dover bloccare un dispositivo da remoto, con il nostro servizio è semplice! In più, ci occupiamo anche della <strong class="font-weight-bold"> cancellazione certificata dei dati</strong> dai dispositivi che non utilizzi più. </p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/ipergaranzia.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Ipergaranzia Elmec </strong> – Manutenzioni e riparazioni sono il nostro pane quotidiano. Andiamo oltre alla garanzia del produttore e seguiamo le attività di assistenza hardware contando su un magazzino di dispositivi sostitutivi pre-configurati da consegnare agli utenti che ne abbiano necessità.</p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img src="img/office365.svg" alt="icon image" class="img-fluid">
                                </div>
                                <p class="mb-0"><strong class="font-weight-bold"> Licenze Office 365 </strong>–   I tool di Office sono ormai una dotazione standard, siamo così abituati ad utilizzarli che a volte ci dimentichiamo che non sono automaticamente attivi sui nuovi device. Con Noltech, invece, è proprio così: gestiamo noi le tue licenze Microsoft a partire dalla parte contrattuale fino al deployment.</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="container"><div class="row  justify-content-center ptb-60"><a @click="richiediPreventivo" class="btn solid-btn">Configura i tuoi device</a></div></div>
        </div>
    </section>



    <div id="features" class="feature-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <!--<span class="text-uppercase color-secondary sub-title">Best features</span>-->
                        <h2>Ti semplifichiamo la vita</h2>
                        <p>In un contesto in cui l’organizzazione delle aziende si è fatta sempre più agile, far sentire i dipendenti parte di un team e garantire l’operatività è una delle sfide degli HR. Con la nostra offerta DaaS vorremmo supportarti in queste attività.    </p>

                    </div>
                </div>
            </div>

            <!--feature new style start-->
            <div class="row row-grid align-items-center">
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-1 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Produttività potenziata</h5>
                            <p class="mb-0">  Migliora la produttività dei tuoi dipendenti fornendo loro device di ultima generazione e un servizio tecnico in grado di gestire tempestivamente eventuali malfunzionamenti.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-2 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Onboarding ottimizzato  </h5>
                            <p class="mb-0"> I nuovi dipendenti potranno iniziare il loro percorso in azienda con dispositivi della migliore qualità, ready-to-use e un benvenuto personalizzato.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-3 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Benefit  </h5>
                            <p class="mb-0">I device tecnologici non sono solo uno strumento di lavoro, ma un vero e proprio benefit. Scegli tra i modelli top di gamma dei migliori vendor del mercato.  </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="position-relative" style="z-index: 10;">
                        <img alt=" I vantaggi Noltech per l'HR Manager" src="img/hr.svg" class="img-center img-fluid">
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-4 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Configurazioni personalizzate</h5>
                            <p class="mb-0">Non ci limitiamo a fornirti i dispositivi, ma li configuriamo in base alle tue indicazioni in modo che al momento della consegna siano pronti all’uso.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-5 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Communication kit  </h5>
                            <p class="mb-0">Sorprendi i tuoi dipendenti con un imballo personalizzato contente anche un messaggio di benvenuto e un QR code con un video di spiegazione delle caratteristiche dei device.</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div class="icon icon-shape icon-color-6 rounded-circle">
                                <span><img src="img/stelle.svg"/></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Remote working</h5>
                            <p class="mb-0">I tuoi dipendenti lavorano in smart working? Allora i loro nuovissimi device arriveranno direttamente a casa loro e non dovrai preoccuparti della loro consegna.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--feature new style end-->
        </div>
    </div>





    <section id="tabella" class="package-section gray-light-bg ptb-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="section-heading text-center mb-5">
              <h2>Perché i nostri clienti si sono affidati a Noltech </h2>
              <p class="lead">
                  Il modello DaaS di Noltech si adatta alle necessità dei nostri clienti.
              </p>
                <p class="lead">
                    Mettici alla prova,<strong class="color-primary font-weight-bold"> i nostri contratti sono senza asterischi!</strong>
                </p>
            </div>
          </div>
        </div>
        <table class="table ">
          <thead class="table-info">
          <tr>

            <th><strong class=" font-weight-bold">Servizi più richiesti dalle aziende</strong></th>
            <th style="text-align: center"><strong class=" font-weight-bold">NolTech</strong>​​</th>
            <th style="text-align: center"><strong class=" font-weight-bold">Gli altri</strong></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td> Unico Interlocutore </td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center">

              <img src="/img/close.svg" width="30" alt="check" class="img-fluid">
              <img src="/img/information.svg" width="16px" v-tooltip.right="'I contratti di noleggio coinvolgono spesso due o più società diverse per gli aspetti tecnici ed amministrativi. Chi è il tuo vero interlocutore?'">
            </td>

          </tr>
          <tr>
            <td>Ci ripenso: restituisci la tecnologia a zero spese</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid">
              <img src="/img/information.svg" width="16px" v-tooltip.right="'Fai attenzione alle condizioni di restituzione di uno o più beni a fine contratto: spesso nascondono clausole vincolanti'">
            </td>

          </tr>
          <tr>
            <td>Contratto aperto e trasparente</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img src="/img/information.svg" width="16px"
                                               v-tooltip.right="'Controlla le clausole assicurative, di esclusione in caso di smarrimento/furto con destrezza o i vincoli di estensione del contratto. se riesci a trovarle'">
            </td>

          </tr>
          <tr>
            <td>Tacito Rinnovo</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Controlla le clausole assicurative, di esclusione in caso di smarrimento/furto con destrezza o i vincoli di estensione del contratto. se riesci a trovarle'"
            >
            </td>

          </tr>
          <tr>
            <td>Servizio EU</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Sei un business europeo/multinazionale: ti occorre una fatturazione separabile su più country. Con altre società avrai più contratti e dovrai gestire il “transfer price.'">
            </td>

          </tr>
          <tr>
            <td>Flessibilità</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"><img style="padding-left: 1px"
                                                                       src="/img/information.svg" width="16px"
                                                                       v-tooltip.right="'Se il tuo business è scalabile nel tempo e vuoi aggiungere device di volta in volta, possono garantirti più lotti noleggiati in tempi diversi?'">
            </td>

          </tr>
          <tr>
            <td>Intervento nelle 24 h</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style="margin-right: 14px" src="/img/question-mark.svg" width="30"
                                                alt="check"
                                                class="img-fluid"></td>

          </tr>
          <tr>
            <td>EmbeddedCyberSecurity</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style="margin-right: 14px" src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"></td>

          </tr>
          <tr>
            <td>Competenza Tecnica certificata</td>
            <td style="text-align: center"><img src="/img/check.svg" width="30" alt="check"
                                                class="img-fluid"></td>
            <td style="text-align: center"><img style=" margin-right: 14px" src="/img/close.svg" width="30" alt="check"
                                                class="img-fluid"></td>
          </tr>

          </tbody>
        </table>
      </div>
    </section>




</div>
</template>
<script>
    export default {
        name: 'cfo'
    }
</script>
<style scoped>
</style>



<script>
    import HubspotSignup from '../HubspotForm'
    import {commonMixins} from '../../mixins/common-mixins'
    import Multiselect from '../global/Multiselect'
    import MyInput from '../global/MyInput'

    export default {
        name: 'Home',
        mixins: [commonMixins],
        components: {MyInput, Multiselect, HubspotSignup},
        watch: {},
        data () {
            return {
                selectedDevice: '',
                deviceQuantity: null
            }
        },
        mounted: function () {
            this.goTopPage()
        },
        methods: {
            selectDevice: function (device) {
                this.$router.push({
                    name: 'Configurator',
                    params: {
                        deviceType: device,
                        deviceQuantity: this.deviceQuantity
                    }
                })
            },
            goToRoute: function (route) {
                this.$router.push({
                    name: route,
                })
            }
        }
    }
</script>

<style lang="less">
    #hrManager {
        .configuration-btn {
            margin-top: 20px;
            width: 240px;
            height: 40px;
            outline: none;
            background: #F3F7FA 0% 0% no-repeat padding-box;
            border: 1px solid #2C5593;
            border-radius: 25px;
            opacity: 1;
            color: #2C5593;
            font-size: 16px;
            font-weight: bold;
            padding-top: 4px;
        }
        .btn {
            cursor: pointer;
        }

        .card-body img{
            height: 110px !important;
        }
        .flip-div{
            cursor: pointer;
        }
        .flip-div:hover .back,.flip-div.hover .back {
            -webkit-transform: rotateY(0deg);
            -moz-transform: rotateY(0deg);
            -o-transform: rotateY(0deg);
            -ms-transform: rotateY(0deg);
            transform: rotateY(0deg);
        }
        .flip-div:hover .front,.flip-div.hover .front {
            -webkit-transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            -o-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }
        .flip-main{
            -webkit-transition: 1s;
            -webkit-transform-style: preserve-3d;
            -ms-transition: 1s;
            -moz-transition: 1s;
            -moz-transform: perspective(1000px);
            -moz-transform-style: preserve-3d;
            -ms-transform-style: preserve-3d;
            transition: 1s;
            transform-style: preserve-3d;
            position: relative;
        }
        .front{
            position: relative;
            -webkit-transform: rotateY(0deg);
            -ms-transform: rotateY(0deg);
            z-index: 2;
            margin-bottom: 30px;
        }
        .back{
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transform: rotateY(-180deg);
            -moz-transform: rotateY(-180deg);
            -o-transform: rotateY(-180deg);
            -ms-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
            min-height: 250px;
            background-color: #2C5693;
            color: white;
            border-radius: 1rem;

        }
        .back ul li a{
            font-size: 18px;
        }
        .front,.back {
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transition: 1s;
            -webkit-transform-style: preserve-3d;
            -moz-transition: 1s;
            -moz-transform-style: preserve-3d;
            -o-transition: 1s;
            -o-transform-style: preserve-3d;
            -ms-transition: 1s;
            -ms-transform-style: preserve-3d;
            transition: 1s;
            transform-style: preserve-3d;
        }
        .card-flip{
            border: 1px solid #eff2f7;
            border-radius: 1rem;
            box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
        }
        .numcard{
            font-weight: bold;
        }
        .single-promo-1{
            min-height: 250px;
        }
    }
</style>
